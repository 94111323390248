export default {


    column_author: [{
        title: '播主名称',
        dataIndex: 'nickname',
        align: "center",
        width: 220,
        scopedSlots: {
            customRender: 'authorInfo'
        }
    }, {
        title: '平台',
        dataIndex: 'platform',
        align: "center",
        width: 100,
        scopedSlots: {
            customRender: 'platformInfo'
        }
    }, {
        title: '平台授权',
        dataIndex: 'companyName',
        align: "center",
        width: 200,
        scopedSlots: {
            customRender: 'authInfo'
        }
    }, {
        title: '经销商信息',
        dataIndex: 'dealerName',
        align: "center",
        width: 200,
        scopedSlots: {
            customRender: 'dealerInfo'
        }
    }, {
        title: '作品统计',
        dataIndex: 'awemeCountTotal',
        align: "center",
        width: 140,
        scopedSlots: {
            customRender: 'awemeCountTotal'
        }
    }, {
        title: '直播场次',
        dataIndex: 'countLive',
        align: "center",
        width: 100,
        scopedSlots: {
            customRender: 'countLive'
        }
    }, {
        title: '互动统计',
        dataIndex: 'fansCountTotal',
        align: "center",
        width: 140,
        scopedSlots: {
            customRender: 'fansCountTotal'
        }
    }, {
        title: '进组时间',
        dataIndex: 'ctime',
        align: "center",
        width: 120,
    }, {
        title: '操作',
        dataIndex: 'action',
        align: "center",
        scopedSlots: {
            customRender: "action"
        },
        width: 140,
        fixed: "right",
    }, ]

}
<template>
  <div>
    <div class="flex_b">
      <div class="border_right">
        <div class="flex_sp mgb-12">
          <h4>我的播主分组</h4>
          <a-button
            type="primary"
            @click="
              () => {
                addAuthorGroupVisible = true;
              }
            "
            >新建分组</a-button
          >
        </div>
        <common-table
          :headerData="headerData"
          :centerData="groupList"
          @changeList="getMyAuthorGroup"
          :type="2"
          @getDealerList="getMyDealerList"
          ref="commTable"
          @getGroupDetail="getGroupDetail"
        >
        </common-table>
        <group-detail
          :detailData="detailData"
          @handleChangeAuthorGroup="handleChangeAuthorGroup"
          @updataList="searchList"
        />
      </div>
      <div class="content-right">
        <!-- 搜索条件 -->
        <a-form-model
          ref="searchFrom"
          :model="searchForm"
          class="search-form"
          layout="inline"
        >
          <a-form-model-item label="媒体号">
            <a-input
              placeholder="请输入"
              v-model.trim="searchForm.code"
              :maxLength="20"
              style="width: 200px"
            />
          </a-form-model-item>

          <a-form-model-item label="播主昵称/经销商名称/公司名称">
            <a-input
              placeholder="请输入"
              v-model.trim="searchForm.name"
              :maxLength="20"
              style="width: 200px"
            />
          </a-form-model-item>
          <div>
            <a-form-model-item label="平台">
              <a-select
                v-model="searchForm.platform"
                show-search
                option-filter-prop="children"
                allow-clear
                placeholder="请选择"
                style="width: 200px"
              >
                <a-select-option :value="1"> 抖音 </a-select-option>
                <a-select-option :value="2"> 快手 </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="P2授权">
              <a-select
                v-model="searchForm.p2OauthStatus"
                show-search
                option-filter-prop="children"
                allow-clear
                placeholder="请选择"
                style="width: 200px"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in acdtType"
                  :key="item.id"
                >
                  {{ item.lebal }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="P1授权">
              <a-select
                v-model="searchForm.p1OauthStatus"
                show-search
                option-filter-prop="children"
                allow-clear
                placeholder="请选择"
                style="width: 200px"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in acdtType"
                  :key="item.id"
                >
                  {{ item.lebal }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div style="display: flex;justify-content: flex-end;">
            <a-form-model-item>
                <a-button
                  type="primary"
                  style="margin-right: 8px"
                  @click="searchList"
                  >查询</a-button
                >
                <a-button @click="resetList" style="margin-right: 28px"
                  >重置</a-button
                >
                <a-button
                  type="primary"
                  :loading="exportLoading"
                  @click="jumpAuthorGroupDetail"
                  style="margin-right: 8px"
                  v-if="searchForm.isCommon == 1"
                >
                  查看分组详情
                </a-button>
                <a-button
                  type="primary"
                  :loading="exportLoading"
                  @click="handleExport"
                >
                  导出
                </a-button>
            </a-form-model-item>
          </div>
        </a-form-model>

        <div style="padding-top: 20px ">
          <cn-table
            :configPayload="configAuthor"
            :selectValue="searchForm"
            ref="cntable"
            :initData="false"
            :scrollSetting="{ x: 1360, y: 600 }"
          >
            <div class="author-info" slot="authorInfo" slot-scope="text">
              <div class="author-info__hd">
                <a-avatar :src="text.avatar" :size="64" icon="user" />
              </div>
              <div class="author-info__bd">
                <div>昵称：{{ text.nickname || "-" }}</div>
                <div>媒体号：{{ text.code}}</div>
              </div>
            </div>
            <div slot="platformInfo" slot-scope="text">
              {{ text.platform == 1 ? "抖音" : "快手" }}
            </div>
            <div slot="authInfo" slot-scope="text" class="text-info">
              <div>
                互动数据(P2)：{{ text.p2OauthStatus | p2OauthStatusFilter }}
              </div>
              <div>
                直播数据(P1)：{{ text.p1OauthStatus | p1OauthStatusFilter }}
              </div>
              <!-- <div v-if="text.platform == 1">
                线索数据(P9)：{{ text.p9OauthStatus | p9OauthStatusFilter }}
              </div> -->
            </div>
            <div slot="dealerInfo" slot-scope="text" class="text-info">
              <div>名称：{{ text.dealerName || "-" }}</div>
              <div>编码：{{ text.dealerStoreCode || "-" }}</div>
              <div>大区：{{ text.dealerAreaNames || "-" }}</div>
              <div>城市：{{ text.dealerCityName || "-" }}</div>
              <div>主体：{{ text.principalNames || "-" }}</div>
            </div>
            <div slot="countLive" slot-scope="text">
              {{ text.countLive | changeNum }}
            </div>
            <div slot="awemeCountTotal" slot-scope="text" class="text-info">
              <div>作品数：{{ text.awemeCountTotal | changeNum }}</div>
              <div>模板作品数：{{ text.useCount | changeNum }}</div>
            </div>
            <div slot="fansCountTotal" slot-scope="text" class="text-info">
              <div>粉丝数：{{ text.fansCountTotal | changeNum }}</div>
              <div>点赞量：{{ text.likeCountTotal | changeNum }}</div>
            </div>
            <div slot="action" slot-scope="text">
              <router-link
                :to="{
                  name: 'authorAnalysis',
                  params: { authorId: text.authorId },
                  query: { platform: text.platform },
                }"
                target="_blank"
              >
                查看详情
              </router-link>
              <a-divider type="vertical" />
              <a-button type="link" @click="deleteAuthor(text)"
                >从分组删除</a-button
              >
            </div>
          </cn-table>
        </div>
      </div>
    </div>
    <AddAuthorGroupModal
      ref="addAuthorGroupModal"
      v-model="addAuthorGroupVisible"
      :form="addAuthorGroupForm"
      :confirm-loading="addAuthorGroupLoading"
      @confirm="handleAddAuthorGroupConfirm"
    />
    <ChangeAuthorGroupModal
      ref="changeAuthorGroupModal"
      v-model="changeAuthorGroupVisible"
      :form="changeAuthorGroupForm"
      :confirm-loading="changeAuthorGroupLoading"
      @confirm="handleChangeAuthorGroupConfirm"
    />
  </div>
</template>
<script>
import _http from "@/api/personalcenter";
import columnArr from "./columns.js";
import CnTable from "@/components/CnTable";
import CommonTable from "@/components/MyGroup/CommonTable";
import GroupDetail from "@/components/MyGroup/GroupDetail";
import authorApi from "@/api/author";
import AddAuthorGroupModal from "@/components/AddAuthorGroupModal";
import ChangeAuthorGroupModal from "@/components/ChangeAuthorGroupModal";
import {
  platformFilter,
  p2OauthStatusFilter,
  p1OauthStatusFilter,
  p9OauthStatusFilter
} from "../authorList/constant";
export default {
  data() {
    return {
      searchForm: {
        name: "",
        groupId: "",
        isCommon: "",
        code: "",
        platform: undefined,
        p1OauthStatus: undefined,
        p2OauthStatus: undefined,
      },
      configAuthor: {
        columns: columnArr.column_author,
        http_api: _http.getAuthorList,
      },
      groupList: [],
      headerData: ["播主分组", "类型", "操作"],
      addDealerGroupVisible: false,
      addDealerGroupForm: {
        groupType: 1,
        groupName: "",
        leaders: [],
        coordinators: [],
      },
      addAuthorGroupVisible: false,
      addAuthorGroupLoading: false,
      addAuthorGroupForm: {
        groupType: 1,
        groupName: "",
      },
      exportLoading: false,
      acdtType: [
        {
          id: 0,
          lebal: "未授权",
        },
        {
          id: 1,
          lebal: "已授权",
        },
        {
          id: 2,
          lebal: "授权过期",
        },
        {
          id: 4,
          lebal: "处理中",
        },
      ],
      detailData: {},
      changeAuthorGroupVisible: false,
      changeAuthorGroupForm: {
        groupName: "",
        groupAuthorCount: "",
        groupAuthorList: [],
        newGroupType: "",
        newGroupName: "",
      },
      changeAuthorGroupLoading: false,
    };
  },
  components: {
    CnTable,
    CommonTable,
    AddAuthorGroupModal,
    GroupDetail,
    ChangeAuthorGroupModal,
  },
  created() {
    this.getMyAuthorGroup(1);
  },
  methods: {
    // 获取播主分组
    getMyAuthorGroup(type) {
      _http.getMyAuthorGroup().then((res) => {
        if (res.code == 200) {
          this.groupList = res.data;
          if (type) this.$refs.commTable.selectGroup(res.data[0]);
        } else {
          this.$message.error(res.errmsg);
        }
      });
    },
    getMyDealerList(value) {
      const { groupId, isCommon } = value;
      this.searchForm.groupId = groupId;
      this.searchForm.isCommon = isCommon;
      this.searchList();
    },
    resetList() {
      // this.searchForm.dealerName = "";
      const { groupId, isCommon } = this.searchForm;
      this.searchForm = {
        name: "",
        groupId: groupId,
        isCommon: isCommon,
        code: "",
        platform: undefined,
        p1OauthStatus: undefined,
        p2OauthStatus: undefined,
      };
      this.$nextTick(() => {
        this.searchList();
      });
    },
    // 搜索列表
    searchList() {
      // console.log(this.searchForm.dealerName);
      this.$refs.cntable.getTableList(1, 10);
    },
    disposeRename() {
      // console.log(12);
    },
    handleAddDealerGroupOk() {
      this.addDealerGroup();
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true;
      try {
        await _http.getMyAuthorExport(this.searchForm);
        this.exportLoading = false;
      } catch (e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    gotoDelDetail(id) {
      this.$router.push({
        name: "dealerDetail",
        params: {
          dealer_id: id,
        },
      });
    },
    // 新建播主分组
    async addAuthorGroup() {
      this.addAuthorGroupLoading = true;
      try {
        const { groupType, groupName } = this.addAuthorGroupForm;
        const params = {
          groupType,
          groupName,
        };

        groupType === 2 && (params.source = 2);

        const { code, message } = await authorApi.addAuthorGroup(params);

        this.addAuthorGroupLoading = false;

        if (code === 200) {
          this.$message.success("新建播主分组成功");
          this.addAuthorGroupVisible = false;
          this.$refs.addAuthorGroupModal.resetForm();
          this.getMyAuthorGroup();
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
        this.addAuthorGroupLoading = false;
      }
    },
    handleAddAuthorGroupConfirm() {
      this.addAuthorGroup();
    },
    // 获取分组详细信息
    getGroupDetail(id) {
      _http.getAuthorGroupDetail(id).then((res) => {
        if (res.code == 200) {
          this.detailData = res.data;
        }
      });
    },
    deleteAuthor(value) {
      const { groupId, authorId } = value;
      let payload = {
        commonGroupId: this.searchForm.groupId,
        authorId: authorId,
      };
      let isCommon = this.searchForm.isCommon;
      let http_api = ["delAuthorPrivately", "delAuthorCommon"];
      _http[http_api[isCommon]](payload).then((res) => {
        if (res.code == 200) {
          this.searchList();
        } else {
          this.$message.error(res.errmsg);
        }
      });
    },
    // 处理公转私
    async handleChangeAuthorGroup() {
      this.changeAuthorGroupVisible = true;
      try {
        const params = {
          groupId: this.detailData.groupId,
          type: 2,
        };
        const { code, data, message } = await authorApi.copySourceAutorGroup(
          params
        );

        if (code === 200) {
          this.changeAuthorGroupForm.groupName = data.groupName;
          this.changeAuthorGroupForm.groupAuthorCount = data.authorAccountCount;
          this.changeAuthorGroupForm.groupAuthorList = data.authorList;
          this.changeAuthorGroupForm.newGroupType = data.newGroupType;
          this.changeAuthorGroupForm.newGroupName = data.newGroupName;
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 处理公转私确定
    async handleChangeAuthorGroupConfirm() {
      this.changeAuthorGroupLoading = true;
      try {
        const { newGroupType, newGroupName, groupAuthorList } =
          this.changeAuthorGroupForm;
        const params = {
          groupList: [
            {
              groupType: newGroupType,
              groupName: newGroupName,
              source: 1,
            },
          ],
          authorList: groupAuthorList,
        };
        const { code, message } = await authorApi.changeAuthorGroup(params);

        this.changeAuthorGroupLoading = false;

        if (code === 200) {
          this.$message.success("播主分组公转私成功");
          this.changeAuthorGroupVisible = false;
          this.getMyAuthorGroup();
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
        this.changeAuthorGroupLoading = false;
      }
    },
    jumpAuthorGroupDetail() {
      let routeUrl = this.$router.resolve({
        path: `/mediaCenter/authorGroupDetail/${this.searchForm.groupId}`
      })
      window.open(routeUrl.href, '_blank')
    }
  },
  filters: {
    platformFilter,
    p2OauthStatusFilter,
    p1OauthStatusFilter,
    p9OauthStatusFilter
  },
};
</script>
<style lang="scss" scoped>
.mgb-12 {
  margin-bottom: 12px;
}
.flex_b {
  display: flex;
}
.flex_sp {
  display: flex;
  justify-content: space-between;
}
.border_right {
  border-right: 1px solid #ccc;
  width: 30%;
  padding-right: 10px;
  flex-shrink: 0;
  margin-right: 10px;
}
.content-right {
  // flex-shrink: 0;
  // flex: 1;
  width: 100%;
  overflow: hidden;
}
.author-info {
  display: flex;
  align-items: center;
  text-align: left;
  &__hd {
    margin-right: 8px;
  }
}
.text-info {
  text-align: left;
}
</style>
